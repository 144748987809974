<template>
  <div>
    <b-sidebar
      id="sidebar-uploads-error-list"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="500px"
    >
      <file-uploads-error-listing
        :closeAction="closeUploadsErrorListSidebar"
        :upload="selectedUpload"
      />
    </b-sidebar>
    <div class="card">
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Search Name..."
              debounce="500"
              size="md"
              v-model="filters.search"
            />
          </b-input-group>
        </div>
      </div>
      <div class="card-company-table card card-congratulation-medal">
        <div class="mb-0 table-responsive" style="min-height: 55vh">
          <table role="table" class="table b-table table-hover">
            <thead role="rowgroup header">
              <tr role="row">
                <th role="columnheader" scope="col" class="text-center">
                  SL No:
                </th>
                <th role="columnheader" scope="col">File Name</th>
                <th role="columnheader" scope="col">Uploaded At</th>
                <th role="columnheader" scope="col">Status</th>
              </tr>
            </thead>
            <tbody class="rowgroup">
              <template v-if="uploads && uploads.length > 0">
                <tr
                  v-for="(upload, index) in uploads"
                  :key="upload._id"
                  role="row"
                  class="custom-table-row"
                >
                  <td scope="row" class="text-center mx-1" style="width: 120px">
                    {{ index + pagination.from }}
                  </td>
                  <td role="cell">{{ upload.original_file_name }}</td>
                  <td role="cell">{{ upload.created_at | moment }}</td>
                  <td role="cell">
                    <b-badge
                      v-if="upload.process_status == 'failed'"
                      v-b-tooltip.hover.left.v-danger
                      title="Click to view the errors."
                      class="cursor-pointer"
                      @click="handleFailedBadgeClick(upload)"
                      :variant="mapBadgeVariantToStatus(upload.process_status)"
                      >{{ mapTextToStatus(upload.process_status) }}</b-badge
                    >
                    <b-badge
                      v-else
                      :variant="mapBadgeVariantToStatus(upload.process_status)"
                      >{{ mapTextToStatus(upload.process_status) }}</b-badge
                    >
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="No Uploads Yet">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      You can upload files using the upload button of the corresponding questionnaire.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-body">
        <b-pagination
          v-model="filters.page"
          :per-page="pagination.perPage"
          hide-goto-end-buttons
          :total-rows="pagination.lastPage * pagination.perPage"
          class="float-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BPagination,
  BBadge,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  VBTooltip,
  BSidebar,
} from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import moment from "moment";
import EmptyTableSection from "../../components/EmptyTableSection.vue";
import FileUploadsErrorListing from "./FileUploadsErrorListing.vue";

export default {
  components: {
    BCard,
    BPagination,
    BBadge,
    moment,
    EmptyTableSection,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    FileUploadsErrorListing,
    BSidebar,
  },
  mixins: [ResponseMixins],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      selectedUpload: null,
      uploads: [],
      filters: {
        search: null,
        page: 1,
      },
      pagination: {
        perPage: 10,
        total: 1,
        lastPage: 1,
        from: 1,
      },
    };
  },
  watch: {
    filters: {
      deep: true,
      handler: function (newValue) {
        this.getUploadsAndSetData(newValue);
      },
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("LLLL");
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getUploadsAndSetData(this.filters);
    },

    handleFailedBadgeClick(upload) {
      this.selectedUpload = upload;
      this.openUploadsErrorListSidebar();
    },

    openUploadsErrorListSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-uploads-error-list");
    },

    closeUploadsErrorListSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-uploads-error-list");
    },

    getUploadsAndSetData(params) {
      const newParams = {
        job_type: "App\\Imports\\TprQuestionnaireImport",
        reference_type: "App\\Models\\TPRQuestionnaire",
        reference_id: this.$route.query.questionnaireId
      };
      Object.keys(params).forEach((key) => {
        if (params[key] && params[key] !== null && params[key] !== "") {
          newParams[key] = params[key];
        }
      });
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/file-uploads`,
        params: newParams,
      };
      this.$http(options)
        .then((res) => {
          console.log(res);
          this.uploads = res.data.data.data;
          this.$set(this.pagination, "perPage", res.data.data.per_page);
          this.$set(this.pagination, "total", res.data.data.total);
          this.$set(this.pagination, "lastPage", res.data.data.last_page);
          this.$set(this.pagination, "from", res.data.data.from);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    mapBadgeVariantToStatus(variant) {
      switch (variant) {
        case "completed":
          return "success";
        case "pending":
          return "warning";
        case "processing":
          return "primary";
        case "failed":
          return "danger";
        default:
          return "primary";
      }
    },

    mapTextToStatus(variant) {
      switch (variant) {
        case "completed":
          return "Completed";
        case "pending":
          return "Pending";
        case "processing":
          return "Processing";
        case "failed":
          return "Failed";
        default:
          return "Unknown";
      }
    },
  },
};
</script>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }
  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>