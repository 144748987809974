<template>
  <sidebar-template title="Upload Details" :closeAction="closeAction">
    <template #content>
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <div
          class="w-100 h-auto d-flex flex-column align-items-start justify-content-start"
        >
          <div class="d-flex align-items-center justify-content-start">
            <div
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <p class="mb-25 font-weight-bolder">File Name</p>
              <p class="mb-0">{{ upload.original_file_name }}</p>
            </div>
          </div>

            <div
              class="d-flex flex-column align-items-start justify-content-start mt-1"
            >
              <p class="mb-25 font-weight-bolder">Uploaded At</p>
              <p class="mb-0">{{ upload.created_at | moment }}</p>
            </div>

          <div
            class="d-flex flex-column align-items-start justify-content-start mt-1"
          >
            <p class="mb-25 font-weight-bolder">Errors</p>
            <div
              class="d-flex flex-column align-items-start justify-content-start"
            >
                <template v-for="(error,i) in upload.meta.errors">
                  <span :key="error + 'error'" class="d-flex align-items-center justify-content-start mb-50"><b-badge pill variant="danger" class="mr-50">{{i+1}}</b-badge><p class="mb-0">{{ error }}</p></span>
                </template>
            </div>
          </div>
        </div>
      </b-overlay>
    </template>
  </sidebar-template>
</template>

<script>
import { BOverlay, BBadge, BButton } from "bootstrap-vue";
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import moment from "moment";

export default {
  components: {
    BOverlay,
    BBadge,
    BButton,
    SidebarTemplate,
  },

  data() {
    return {
      showOverlay: false,
    };
  },

   filters: {
    moment: function (date) {
      return moment(date).format("LLLL");
    },
  },

  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    upload: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.custom-collapse {
  .card {
    .card-header {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.5rem;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>